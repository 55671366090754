// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-components-proiecte-portfolio-clicked-portfolio-clicked-jsx": () => import("./../../../src/components/Proiecte/PortfolioClicked/PortfolioClicked.jsx" /* webpackChunkName: "component---src-components-proiecte-portfolio-clicked-portfolio-clicked-jsx" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-echipamente-js": () => import("./../../../src/pages/echipamente.js" /* webpackChunkName: "component---src-pages-echipamente-js" */),
  "component---src-pages-home-js": () => import("./../../../src/pages/home.js" /* webpackChunkName: "component---src-pages-home-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-proiecte-js": () => import("./../../../src/pages/proiecte.js" /* webpackChunkName: "component---src-pages-proiecte-js" */)
}

