module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-intl/gatsby-browser.js'),
      options: {"plugins":[],"path":"/Users/gabrielcireap/Projects/g4h/src/locales","languages":["en"],"defaultLanguage":"ro","redirect":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Ground Four Heat","short_name":"G4H","start_url":"/","lang":"ro","description":"Alege incalzirea eficienta prin pompe de caldura iDM Austria. Instalatii, foraje, automatizari si sisteme de ventilatie.","background_color":"#eff0f2","theme_color":"#eff0f2","display":"standalone","icon":"src/images/img.webp","icon_options":{"purpose":"any maskable"},"cache_busting_mode":"none","localize":[{"start_url":"/en/","lang":"en","name":"Ground Four Heat","short_name":"G4H","description":"Choose the efficient heating equipment offered by iDM Austria. We offer installation, automation and ventilation services"}],"legacy":true,"theme_color_in_head":true,"crossOrigin":"anonymous","include_favicon":true,"cacheDigest":null},
    },{
      plugin: require('../node_modules/gatsby-plugin-scroll-reveal/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
